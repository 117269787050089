import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-left text-sm lg:text-base justify-start md:text-sm text-gray-700 font-semibold">
          {title}
        </p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-sm lg:text-base justify-start md:text-sm text-gray-700">
            {children}
          </p>
        </div>
      )}
    </div>
  );
};

const FAQ_referrals = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };
  return (
    <>
      <div className="bg-gray py-16 mb-10">
        <div className=" max-w-1xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"></div>
        <div class="flex flex-wrap overflow-hidden max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2">
            <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
              &mdash; {t("supportmodelauppercase")}
            </p>

            <h2 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {t("referralssection4faqtitle")}
            </h2>
            {/* <p class="mt-4 leading-relaxed text-gray-700">
              {t("referralssection4faqdescription1")}{" "}{t("referralssection4faqdescription2")}
            </p> */}
          </div>
        </div>
        <div className="max-w-screen-xl -mt-6 px-4 mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 row-gap-8 lg:grid-cols-2">
            <div className="space-y-4">
              <div>
                <Item title={t("referralssection4faqitemtitle1")}>
                  {t("referralssection4faqitemdescription1")}
                </Item>
              </div>
              <div>
                <Item title={t("referralssection4faqitemtitle3")}>
                  {t("referralssection4faqitemdescription3")}
                </Item>
              </div>
              <div>
                <Item title={t("referralssection4faqitemtitle5")}>
                  {t("referralssection4faqitemdescription5")}
                </Item>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Item title={t("referralssection4faqitemtitle2")}>
                  {t("referralssection4faqitemdescription2")}
                </Item>
              </div>
              <div>
                <Item title={t("referralssection4faqitemtitle4")}>
                  {t("referralssection4faqitemdescription4")}
                </Item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ_referrals;
