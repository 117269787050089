import React, { useState, useEffect, useRef } from "react";
import useDocumentTitle from "../../useDocumentTitle";
// import Modal_started from "../Home/Modal_started";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import FAQ_referrals from "./FAQ_referrals";
import Modal_referrals from "../Home/Modal_referrals";

const Referrals = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("REFERIDOS | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let scrollLeft = 0;
    let animationId;

    function animateScroll() {
      scrollLeft += 1; 

      if (scrollLeft >= container.scrollWidth - container.clientWidth) {
        clearInterval(animationId);
        setTimeout(() => { 
          scrollLeft = 0;
          animationId = setInterval(animateScroll, 1000 / 60);
        }, 2000);
      }

      container.scrollLeft = scrollLeft;
    }

    setTimeout(() => {
      animationId = setInterval(animateScroll, 1000 / 60); // Iniciar la animación
    }, 2000); 
 
    return () => clearInterval(animationId);
  }, []); 

  return (
      <>
      {isOpen && <Modal_referrals setIsOpen={setIsOpen} />}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="lg:pr-10">
          <h1 class="font-bold xl:text-[48px] lg:text-6xl md:text-5xl text-5xl max-w-lg text-[#002D33]">
          {t("referralssection1title")}
          </h1>
          <p class="mt-5 text-gray-700 leading-loose max-w-2xl font-light">
          {t("referralssection1description")}
          </p>
          <div class="mt-7 grid gap-3 w-full sm:inline-flex">
            <button 
            onClick={() => setIsOpen(true)}
            class="py-4 px-5 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
              {t("referralssection1button")}
            </button>
          </div>
        </div>
        <div>
          <img
            className="object-cover w-full h-56 rounded-2xl shadow-lg sm:h-96"
            src="https://img.invupos.com/webinvu/Referrals/referrals.jpg"
            alt=""
          />
        </div>
      </div>
    </div>

    <section className="bg-gray-50">
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {t("referralssection2title")}
        </h2>
      </div>
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col justify-between p-5 border rounded-2xl shadow-sm bg-white">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full text-white bg-gradient-to-r from-pirLightBlue to-pirPurple">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-gift">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 8m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
                <path d="M12 8l0 13" />
                <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
                <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("referralssection2itemtitle1")}</h6>
            <p className="mb-3 text-sm text-gray-900">
            {t("referralssection2itemdescription1")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded-2xl shadow-sm bg-white">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full text-white bg-gradient-to-r from-pirLightBlue to-pirPurple">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-network">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M6 9a6 6 0 1 0 12 0a6 6 0 0 0 -12 0" />
              <path d="M12 3c1.333 .333 2 2.333 2 6s-.667 5.667 -2 6" />
              <path d="M12 3c-1.333 .333 -2 2.333 -2 6s.667 5.667 2 6" />
              <path d="M6 9h12" />
              <path d="M3 20h7" />
              <path d="M14 20h7" />
              <path d="M10 20a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
              <path d="M12 15v3" />
            </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("referralssection2itemtitle2")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("referralssection2itemdescription2")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded-2xl shadow-sm bg-white">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full text-white bg-gradient-to-r from-pirLightBlue to-pirPurple">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-certificate">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
              <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
              <path d="M6 9l12 0" />
              <path d="M6 12l3 0" />
              <path d="M6 15l2 0" />
            </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("referralssection2itemtitle3")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("referralssection2itemdescription3")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded-2xl shadow-sm bg-white">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full text-white bg-gradient-to-r from-pirLightBlue to-pirPurple">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-heart-handshake">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
              <path d="M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25" />
              <path d="M12.5 15.5l2 2" />
              <path d="M15 13l2 2" />
            </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("referralssection2itemtitle4")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("referralssection2itemdescription4")}
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mt-12">
  <div className="flex flex-col lg:flex-row text-center">
    <div className="max-w-xl mx-auto lg:order-last items-center align-center flex ">
      <div className="p-8">
        <div className="text-center flex justify-center">
          <div class="mt-4 flex gap-x-3 mb-6">
            <a
              class="hover:opacity-90"
              href="https://www.capterra.com.pa/software/1049125/invu-pos"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/ca-ease_of_use-2023.png" className="h-[85px]"/>
            </a>
            <a
              class="hover:opacity-90"
              href="https://www.softwareadvice.com/inventory-management/invu-pos-profile/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/sa-customer_support-2023.png" className="h-[85px]"/>
            </a>
            <a
              class="hover:opacity-90"
              href="https://www.getapp.com/retail-consumer-services-software/a/invu-pos/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/ga-features_and_functionality-2023.png" className="h-[80px]"/>
            </a>
          </div>
        </div>
        <hr className="mb-6 border border-gray-300" />
        <h5 className="mb-6 text-3xl max-w-lg font-extrabold leading-none">
          {t("referralssection3title")}
        </h5>
      </div>
    </div>

    

    <div className="overflow-x-hidden relative custom-container">
    <div className="overflow-x-auto custom-scrollbar" id="cardContainer" ref={containerRef}>
    <div id="carousel" className="grid gap-5 row-gap-5 sm:grid-cols-2 items-center">
      <div className="flex gap-5" style={{ width: '290px' }}>
        {/* Tarjeta 1 */}
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
            alt="Little Caesars"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700">
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-[220px] h-full items-end mx-auto pb-3">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
              />
            </div>
          </div>
        </div>
        {/* Tarjeta 2 */}
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
            <img
              src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
              alt="Krispy Kreme"
              className="object-cover object-center h-96 w-full rounded-3xl"
            />
            <div>
              <div className="absolute inset-x-0 text-center mt-8">
                <span
                  class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                >
                  <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                </span>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
              <div className="flex w-44 h-full items-end mx-auto">
                <img
                  src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                />
              </div>
            </div>
        </div>
        {/* Tarjeta 3 */}
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
            <img
              src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
              alt="Cinnabon"
              className="object-cover h-96 w-full rounded-3xl"
            />
            <div>
              <div className="absolute inset-x-0 text-center mt-8">
                <span
                  class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                >
                  <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                </span>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
              <div className="flex w-40 h-full items-end mx-auto">
                <img
                  src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                />
              </div>
            </div>
        </div>
        {/* Tarjeta 4 */}
       <div className="flex flex-shrink-0 relative w-full sm:w-72">   
          <img
            src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
            alt="El 9 Yucateco"
            className="object-cover object-center h-96 w-full rounded-3xl "
          />

          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
              </span>
            </div>
          </div>
          
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            
            <div className="flex w-24 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
              />
            </div>
          </div>
        </div>
        {/* Tarjeta 5 */}

        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/mandrakephoto1.jpg"
            alt="Mandrake Café"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-42 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
              />
            </div>
          </div>
        </div>

        {/* Tarjeta 6 */}

        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
            alt="Ninja Park"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-44 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
              />
            </div>
          </div>
        </div>

        {/* Tarjeta 7 */}


        {/* Repetir el contenido para crear el bucle */}
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>

    <section class="relative mt-16">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://img.invupos.com/webinvu/Referrals/imgctafinal.png"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-40"></div>

        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-3xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                {t("referralsctauppercase")}
              </p>
            </div>
            <h1 class="text-3xl text-white font-extrabold sm:text-5xl">
              {t("referralsctatitle")}
            </h1>

            <p className="mt-5 max-w-3xl text-gray-200 leading-loose">
             {t("referralsctadescription1")}  <span className="font-semibold">{t("referralsctadescription2")}</span>
            </p>
          </div>
        </div>
      </section>
    
    <FAQ_referrals/>


      </>
  )
}

export default Referrals