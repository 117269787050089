import React, { useState, useEffect } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import useDocumentTitle from "../../useDocumentTitle";
import FAQ_model_one from "./FAQ_model_one";
import Home from "../../pages/Home";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const features = [
  {
    id: "1",
    name: "Getting started",
    description:
      "All you need to know about Invu and how we can help you succeed in a world of giants.",
    imgSrc:
      "https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%202.jpeg",
    href: "/point-of-sale",
  },
  {
    id: "2",
    name: "Using Invu POS",
    description:
      "Learn about the main features of the app and how you can use it to run your business.",
    imgSrc:
      "https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%203.jpg",
    href: "/products-overview",
  },
  {
    id: "3",
    name: "Invu Terminal",
    description:
      "With the Terminal, you can take payments and manage your POS from the same device.",
    imgSrc:
      "https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%204.jpg",
    href: "/hardware-details",
  },
  {
    id: "4",
    name: "Hardware",
    description:
      "Find information to help troubleshootyour Invu POS. Take the hardest out of Hardware solutions.",
    imgSrc:
      "https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%205.webp",
    href: "/hardware-details",
  },
];

const Help_center = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("SOPORTE | INVU POS ");
  const history = useHistory();

  // window.setTimeout(function () {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.drishya",
  //     "_blank"
  //   );
  // }, 1);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  useEffect(() => {
    window.open("https://soporte.invupos.com/es-LA/support/solutions", "_blank");
  }, []);

  // useEffect(() => {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.drishya",
  //     "_blank"
  //   );
  // }, [
  //   window.setTimeout(function () {
  //     window.history.back(-1);
  //   }, 1000),
  // ]);

  return (
    <>
      <div className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 mt-10">
          <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
            <div className="lg:w-1/2 ">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; {t("resourcesmoretoolssupportuppercase")}
              </h2>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                {t("resourcesmoretoolssupporttitle")}
              </h2>
            </div>
            <div className="flex items-end lg:w-1/2">
              <p className="text-base text-gray-700 md:text-medium">
                {t("resourcesmoretoolssupportdescription")}
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1 cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%201.avif"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <section className="bg-pirBlack mt-24">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 ">

              <div>
                <a class="grid grid-cols-1  overflow-hidden rounded-lg group sm:grid-cols-3">
                  <div class="relative">
                    <img
                      class="absolute inset-0 object-cover w-full h-full"
                      src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%202.jpeg"
                      alt=""
                    />
                  </div>
                  <div class="p-8 sm:col-span-2 bg-pirDarkGray">
                    <ul class="flex space-x-1">
                      <li class="inline-block px-3 py-1 text-xs  font-semibold text-white bg-blue-600 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                        {t("resourcesmoretoolssupportcardglobaluppercase")}
                      </li>
                    </ul>
                    <h5 class="mt-4 font-bold text-white underline">
                      {t("resourcesmoretoolssupportcard1title")}
                    </h5>

                    <p class="mt-2 text-sm text-gray-500">
                      {t("resourcesmoretoolssupportcard1description")}
                    </p>
                  </div>
                </a>
              </div>

              <div>
                <a class="grid grid-cols-1  overflow-hidden rounded-lg group sm:grid-cols-3">
                  <div class="relative">
                    <img
                      class="absolute inset-0 object-cover w-full h-full"
                      src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%203.jpg"
                      alt=""
                    />
                  </div>
                  <div class="p-8 sm:col-span-2 bg-pirDarkGray">
                    <ul class="flex space-x-1">
                      <li class="inline-block px-3 py-1 text-xs  font-semibold text-white bg-blue-600 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                        {t("resourcesmoretoolssupportcardglobaluppercase")}
                      </li>
                    </ul>
                    <h5 class="mt-4 font-bold text-white underline">
                      {t("resourcesmoretoolssupportcard2title")}
                    </h5>

                    <p class="mt-2 text-sm text-gray-500">
                      {t("resourcesmoretoolssupportcard2description")}
                    </p>
                  </div>
                </a>
              </div>

              <div>
                <a class="grid grid-cols-1  overflow-hidden rounded-lg group sm:grid-cols-3">
                  <div class="relative">
                    <img
                      class="absolute inset-0 object-cover w-full h-full"
                      src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%204.jpg"
                      alt=""
                    />
                  </div>
                  <div class="p-8 sm:col-span-2 bg-pirDarkGray">
                    <ul class="flex space-x-1">
                      <li class="inline-block px-3 py-1 text-xs  font-semibold text-white bg-blue-600 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                        {t("resourcesmoretoolssupportcardglobaluppercase")}
                      </li>
                    </ul>
                    <h5 class="mt-4 font-bold text-white underline">
                      {t("resourcesmoretoolssupportcard3title")}
                    </h5>

                    <p class="mt-2 text-sm text-gray-500">
                      {t("resourcesmoretoolssupportcard3description")}
                    </p>
                  </div>
                </a>
              </div>

              <div>
                <a class="grid grid-cols-1  overflow-hidden rounded-lg group sm:grid-cols-3">
                  <div class="relative">
                    <img
                      class="absolute inset-0 object-cover w-full h-full"
                      src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%205.webp"
                      alt=""
                    />
                  </div>
                  <div class="p-8 sm:col-span-2 bg-pirDarkGray">
                    <ul class="flex space-x-1">
                      <li class="inline-block px-3 py-1 text-xs  font-semibold text-white bg-blue-600 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                        {t("resourcesmoretoolssupportcardglobaluppercase")}
                      </li>
                    </ul>
                    <h5 class="mt-4 font-bold text-white underline">
                      {t("resourcesmoretoolssupportcard4title")}
                    </h5>

                    <p class="mt-2 text-sm text-gray-500 max-w-4xl">
                      {t("resourcesmoretoolssupportcard4description")}
                    </p>
                  </div>
                </a>
              </div>

          </div>
        </div>
      </section>

      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 mt-10">
        <div className="bg-gray-50 rounded-lg grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1  md:gap-12 gap-14  lg:py-12 py-10 md:px-12 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-pirOrange">
          <div>
            <div className="flex items-center justify-center w-16 h-16  rounded-full bg-indigo-50 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </div>
            <h3 className=" text-xl leading-5 font-semibold text-white lg:mt-10 mt-6 ">
              {t("resourcesmoretoolssupportsection3card1title")}
            </h3>
            <p className=" text-base leading-6 font-normal text-white mt-4 lg:w-full md:w-9/12 w-full">
             {t("resourcesmoretoolssupportsection3card1description")}
            </p>
          </div>

          <div>
            <div className="flex items-center justify-center w-16 h-16  rounded-full bg-indigo-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <h3 className=" text-xl leading-5 font-semibold text-white lg:mt-10 mt-8 ">
              {t("resourcesmoretoolssupportsection3card2title")}
            </h3>
            <p className=" text-base leading-6 font-normal text-white mt-4 lg:w-full md:w-9/12 w-full">
              {t("resourcesmoretoolssupportsection3card2description")}
            </p>
          </div>

          <div>
            <div className="flex items-center justify-center w-16 h-16  rounded-full bg-indigo-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <h3 className=" text-xl leading-5 font-semibold text-white lg:mt-10 mt-6">
              {t("resourcesmoretoolssupportsection3card3title")}
            </h3>
            <p className=" text-base leading-6 font-normal text-white mt-4 lg:w-full md:w-9/12 w-full">
              {t("resourcesmoretoolssupportsection3card3description")}
            </p>
          </div>

          <div>
            <div className="flex items-center justify-center w-16 h-16  rounded-full bg-indigo-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className=" text-xl leading-5 font-semibold text-white lg:mt-10 mt-6 ">
              {t("resourcesmoretoolssupportsection3card4title")}
            </h3>
            <p className=" text-base leading-6 font-normal text-white mt-4 lg:w-full md:w-9/12 w-full">
              {t("resourcesmoretoolssupportsection3card4description")}
            </p>
          </div>
        </div>
      </div>

      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Help_center;
