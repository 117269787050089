import { Fragment, useState, useEffect } from "react";
import {
  Dialog,
  Popover,
  Tab,
  Transition,
  Listbox,
  Menu,
} from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/solid";
// import { Link } from "react-router-dom";

import { scroller } from "react-scroll";
import { motion } from "framer-motion";

// import i18n from "../../_i18n/i18n";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../../components/utils/useLocalePrefix";

import i18n from "i18next";
import { changeLanguage } from "../../../components/utils";
import { withRouter } from "react-router-dom";
import { Select, Option } from "@material-tailwind/react";
import Form from "react-bootstrap/Form";
import Modal_started from "../../../components/Home/Modal_started";

// function changeLanguageOnClick(language) {
//   i18n.changeLanguage(language);
// }

const navigation = {
  categories: [
    {
      id: "products",
      name: "Products",
      featured: [
        {
          name: "The future of Retail",
          href: "/retail-overview",
          imageSrc:
            "https://res.cloudinary.com/dslkvd23s/image/upload/v1649182319/Branding%20Invu%20/Hero%20/The_retail_final_nd7w36.png",
          imageAlt: "",
        },
        {
          name: "The future of restaurants",
          href: "/ecommerce",
          imageSrc:
            "https://res.cloudinary.com/dslkvd23s/image/upload/v1649183649/Branding%20Invu%20/Hero%20/the_future_of_restaurants_wsjs8f.png",
          imageAlt: "",
        },
      ],
      sections: [
        {
          id: "payments",
          name: "Payments",
          items: [
            { name: "Invu payments", href: "/payments-overview" },
            {
              name: "In person",
              href: "/payments/in-person-payments",
            },
            { name: "On your computer", href: "/payments/on-your-computer" },
            { name: "On your website", href: "/payments/on-your-website" },
            { name: "Risk manage", href: "/payments/risk-manage" },
            { name: "Platform payments", href: "/payments/platform" },
            { name: "Processors", href: "/payments/processor" },
            { name: "Tranfers", href: "/transfers" },
          ],
        },
        {
          id: "products",
          name: "Products",
          items: [
            { name: "Invu Point of sale", href: "/point-of-sale" },
            { name: "Invu2GO E-commerce", href: "/ecommerce" },
            { name: "Invu Loyalty", href: "/customer-loyalty" },
            { name: "Invu Plus", href: "/products/Plus" },
            { name: "Invu KDS", href: "/products/KDS" },
            { name: "Invu Kiosks", href: "/products/Kiosks" },
            { name: "Invu Marketing", href: "/products/Marketing" },
          ],
        },

        {
          id: "customers",
          name: "Customers",
          items: [
            { name: "Contact sales", href: "/contact-sales" },
            { name: "Accounting ERP's", href: "/accounting-erps" },
            { name: "Purchase", href: "/purchase" },
            { name: "Invu guides", href: "/resources/guides" },
            { name: "Resellers Program", href: "/resellers-program" },
            { name: "Resources Overview", href: "/resources-overview" },
          ],
        },
        {
          id: "developer",
          name: "Developer",
          items: [
            { name: "Developer Overview", href: "/developers" },
            { name: "Resources Blog", href: "/resources/blog" },
            { name: "Invu expert", href: "/invu-experts" },
          ],
        },

        {
          id: "help",
          name: "Help",
          items: [{ name: "Support center", href: "/help-center" }],
        },
      ],
    },
    {
      id: "businesstypes",
      name: "Business Types",
      featured: [
        {
          name: "The point sale",
          href: "/point-of-sale",
          imageSrc:
            "https://res.cloudinary.com/dslkvd23s/image/upload/v1649023653/Branding%20Invu%20/Hero%20/pexels-ketut-subiyanto-4473496_oa3w8m.jpg",
          imageAlt: "",
        },
        {
          name: "Management",
          href: "/inventory-managment",
          imageSrc:
            "https://res.cloudinary.com/dslkvd23s/image/upload/v1649022852/Branding%20Invu%20/Hero%20/pexels-liza-summer-6348113_cr58lm.jpg",
          imageAlt: "",
        },
      ],
      sections: [
        {
          id: "retail",
          name: "Retail",
          items: [
            { name: "Overview", href: "/retail-overview" },
            { name: "E-commerce", href: "/ecommerce" },
            { name: "Manage inventory", href: "/inventory-managment" },
            { name: "Invu guides", href: "/resources/guides" },
          ],
        },
        {
          id: "sellmore",
          name: "Sell More",
          items: [
            { name: "Customer loyalty", href: "/customer-loyalty" },
            { name: "Accounting", href: "/accounting-erps" },
            { name: "Transfers", href: "/transfers" },
            { name: "Business types", href: "/general/business-types" },
          ],
        },
        {
          id: "smallbusiness",
          name: "Small Business",
          items: [
            { name: "Overview", href: "/large-business" },
            { name: "Contact sales", href: "/contact-sales" },
            { name: "Retail overview", href: "/retail-overview" },
          ],
        },
        {
          id: "professionalservices",
          name: "Professional Services",
          items: [
            { name: "Overview", href: "/professional-services" },
            { name: "Customer loyalty", href: "/customer-loyalty" },
            {
              name: "Health & Fitness",
              href: "/business-types/health-and-fitness",
            },
            { name: "Coffee Shops", href: "/business-types/coffee-shops" },
            {
              name: "Beauty Professionals",
              href: "/business-types/beauty-professionals",
            },
          ],
        },
        {
          id: "foodandbeverage",
          name: "Food & Beverage",
          items: [
            { name: "Overview", href: "/food-and-beverage" },
            { name: "Product overview", href: "/products-overview" },
          ],
        },
      ],
    },
  ],
  pages: [
    { name: "Why Invu?", href: "/about-us" },
    { name: "Resources", href: "/resources/blog" },
  ],
};

const languaje = [
  {
    id: 1,
    name: "English",
    avatar:
      "https://i.pinimg.com/736x/4c/a5/e1/4ca5e18c26eecb9899187b0093a8c0cc--american-flag-pictures-flags.jpg",
  },
  {
    id: 2,
    name: "Spanish",
    avatar:
      "https://www.pinclipart.com/picdir/middle/102-1028023_big-image-spanish-flag-round-icon-clipart.png",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavBarMX() {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(languaje[1]);
  const [isOpen, setIsOpen] = useState(false);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  useEffect(() => {
    for (
      let index = 0;
      index < document.getElementsByClassName("lang").length;
      index++
    ) {
      const element = document.getElementsByClassName("lang")[index];
      if (element.value === i18n.language) {
        element.setAttribute("selected", "true");
      }
    }
  }, []);

  const navigation = t("navigation", { returnObjects: true });

  const history = useHistory();

  //

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="bg-white sticky relative top-0 z-40">
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 pt-5 pb-2 flex">
                  <button
                    type="button"
                    className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex px-4 space-x-8 flex overflow-x-scroll no-scrollbar">
                      {navigation.categories.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "text-indigo-600 border-indigo-600 "
                                : "text-gray-900 border-transparent",
                              "flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium"
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="pt-10 pb-8 px-4 space-y-10"
                      >
                        <div className="grid grid-cols-2 gap-x-4">
                          {category.featured.map((item) => (
                            <div
                              key={item.name}
                              className="group relative text-sm"
                            >
                              <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className="object-center object-cover"
                                />
                              </div>
                              <a
                                href={item.href}
                                className="mt-6 block font-medium text-gray-900"
                              >
                                <span
                                  className="absolute z-10 inset-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p aria-hidden="true" className="mt-1">
                                Learn More
                              </p>
                            </div>
                          ))}
                        </div>
                        <div className="grid grid-cols-2 gap-x-4">
                          {category.sections.map((section) => (
                            <div className="mt-6" key={section.name}>
                              <p
                                id={`${category.id}-${section.id}-heading-mobile`}
                                className="font-medium text-gray-900 underline"
                              >
                                {section.name}
                              </p>
                              <ul
                                role="list"
                                aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                                className="mt-6 flex flex-col space-y-6"
                              >
                                {section.items.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <Link
                                      to={`${prefix}` + item.href}
                                      onClick={() => {
                                        setOpen(false);
                                        window.scrollTo(0, 0);
                                      }}
                                      className="-m-2 p-2 block text-gray-500"
                                    >
                                      {item.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <Link
                        to={page.href}
                        onClick={() => setOpen(false)}
                        className="-m-2 p-2 block font-medium text-gray-900"
                      >
                        {page.name}
                      </Link>
                    </div>
                  ))}
                </div>

                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                  <div className="flow-root">
                    <button onClick={() => setOpen(false)}>
                      <a
                        href="https://admin.invupos.com/invuPos/index.php/site/login"
                        target="_blank"
                        className="-m-2 p-2 block font-medium text-gray-900"
                      >
                        {t("navbarsignin")}
                      </a>
                    </button>
                  </div>
                  <div className="flow-root">
                    <button onClick={() => setIsOpen(true)}>
                      {/* <a
                        href="https://admin.invupos.com/invuPos/index.php/site/login"
                        target="_blank"
                        className="-m-2 p-2 block font-medium text-gray-900"
                      > */}
                      {t("navbargetdemobutton")}
                      {/* </a> */}
                    </button>
                  </div>
                </div>

                <div className="border-t border-gray-200 py-6 pt-10 pb-8 px-4 space-y-10">
                  <div className="flow-root">
                    <div className="flex items-center bg-white g:ml-8 mr-2 lg:flex">
                      <div className=" lg:ml-8 flex space-x-3 ">
                        <Link to={genLanguagePath("", location)} as={Link}>
                          <img
                            onClick={() => changeLanguage("es")}
                            src="https://img.invupos.com/webinvu/Navbar/Idioms/Spanish%20Flag.png"
                            alt=""
                            className="w-6 h-6 rounded-full border-2  hover:border-indigo-600"
                          />
                        </Link>
                        <Link to={genLanguagePath("en", location)} as={Link}>
                          <img
                            onClick={() => changeLanguage("en")}
                            src="https://img.invupos.com/webinvu/Navbar/Idioms/English%20Flag.png"
                            alt=""
                            className="w-6 h-6 rounded-full border-2  hover:border-indigo-600 "
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        <header className="relative bg-white">
          <nav
            aria-label="Top"
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
          >
            <div className="border-b border-gray-200">
              <div className="h-20 flex items-center">
                <div className=" flex lg:ml-0">
                  <button onClick={() => history.push("/")}>
                    <Link onClick={() => window.scrollTo(0, 0)} to="/">
                      <img
                        className="h-14 w-auto"
                        src="https://img.invupos.com/invu%20venezuela/Logo%20invu%20vn.svg"
                        alt=""
                      />
                    </Link>
                  </button>
                </div>

                <Popover.Group className="hidden lg:ml-56 mt-2 lg:block lg:self-stretch">
                  <div className="h-full flex space-x-8">
                    {navigation.categories.map((category) => (
                      <Popover key={category.name} className="flex ">
                        {({ open }) => (
                          <>
                            <div className="relative flex ">
                              <Popover.Button
                                className={classNames(
                                  open
                                    ? "border-indigo-600 text-indigo-600 "
                                    : "border-transparent text-gray-700 hover:text-gray-800",
                                  "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px"
                                )}
                              >
                                {category.name}
                              </Popover.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Popover.Panel className="absolute  top-full inset-x-0 text-sm text-gray-500">
                                <div
                                  className="absolute inset-0 top-1/2 bg-white shadow"
                                  aria-hidden="true"
                                />


                                <div className="relative bg-white">
                                  <div className="max-w-7xl mx-auto px-8">
                                    <div className="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                                      <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                        {category.featured.map((item) => (
                                          <Link
                                            key={item.name}
                                            to={`${prefix}` + item.href}
                                            onClick={() => {
                                              setOpen(false);
                                              window.scrollTo(0, 0);
                                            }}
                                            className="group relative text-base sm:text-sm"
                                          >
                                            <Popover.Button>
                                            <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                              <img
                                                src={item.imageSrc}
                                                alt={item.imageAlt}
                                                className="object-center object-cover"
                                              />
                                            </div>
                                            <a
                                              href={item.href}
                                              className="mt-6 block font-medium text-gray-900 text-left"
                                            >
                                              <span
                                                className="absolute z-10 inset-0"
                                                aria-hidden="true"
                                              />
                                              {item.name}
                                            </a>
                                            <p
                                              aria-hidden="true"
                                              className="mt-1 text-left"
                                            >
                                              Learn more
                                            </p>
                                            </Popover.Button>
                                          </Link>
                                        ))}
                                      </div>
                                      <div className="row-start-1 grid grid-cols-3 gap-y-10 gap-x-8 text-sm">
                                        {category.sections.map((section) => (
                                          <div key={section.name}>
                                            <p
                                              id={`${section.name}-heading`}
                                              className="font-medium text-gray-900 underline"
                                            >
                                              {section.name}
                                            </p>
                                            <ul
                                              role="list"
                                              aria-labelledby={`${section.name}-heading`}
                                              className="mt-6 space-y-6 sm:mt-4 sm:space-y-4 "
                                            >
                                              {section.items.map((item) => (
                                                <Link
                                                  onClick={() =>
                                                    window.scrollTo(0, 0)
                                                  }
                                                  to={`${prefix}` + item.href}
                                                  key={item.name}
                                                  className="flex"
                                                >
                                                  <Popover.Button className="hover:text-gray-800  w-full text-left">
                                                    <p>{item.name}</p>
                                                  </Popover.Button>
                                                </Link>
                                              ))}
                                            </ul>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    ))}

                    {navigation.pages.map((page) => (
                      <Link
                        onClick={() => window.scrollTo(0, 0)}
                        to={`${prefix}` + page.href}
                        className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                      >
                        {page.name}
                      </Link>
                    ))}
                  </div>
                </Popover.Group>

                <div className="ml-auto flex items-center">
                  <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    <div className="hidden lg:ml-8 lg:flex space-x-3 ">
                      <Link to={genLanguagePath("", location)} as={Link}>
                        <img
                          onClick={() => changeLanguage("es")}
                          src="https://img.invupos.com/webinvu/Navbar/Idioms/Spanish%20Flag.png"
                          alt=""
                          className="w-6 h-6 rounded-full border-2  hover:border-indigo-600"
                        />
                      </Link>
                      <Link to={genLanguagePath("en", location)} as={Link}>
                        <img
                          onClick={() => changeLanguage("en")}
                          src="https://img.invupos.com/webinvu/Navbar/Idioms/English%20Flag.png"
                          alt=""
                          className="w-6 h-6 rounded-full border-2  hover:border-indigo-600 "
                        />
                      </Link>
                    </div>
                    <span class="w-px h-6 bg-gray-300"></span>
                    <button>
                      <a
                        href="https://admin.invupos.com/invuPos/index.php/site/login"
                        target="_blank"
                        className="text-sm font-medium text-gray-700 hover:text-gray-800 "
                      >
                        {t("navbarsignin")}
                      </a>
                    </button>
                    <button
                      className="inline-flex items-center px-4 py-2.5 text-[#000000] bg-[#FED850] rounded-full border border-[#FFCC00] rounded hover:opacity-90 focus:outline-none focus:ring"
                      onClick={() => setIsOpen(true)}
                    >
                      {/* <a
                        class="inline-flex items-center px-4 py-2.5 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                        href="https://admin.invupos.com/invuPos/index.php/site/login"
                        target="_blank"
                      > */}
                      <span class="text-sm font-medium">
                        {" "}
                        {t("navbargetdemobutton")}{" "}
                      </span>
                      {/* </a> */}
                    </button>
                  </div>

                  <button
                    type="button"
                    className="bg-white p-2 rounded-md text-gray-400 lg:hidden"
                    onClick={() => setOpen(true)}
                  >
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
}

export default withRouter(NavBarMX);

function genLanguagePath(locale, location) {
  const lang = i18n.language;

  if (isHomePage(location.pathname)) {
    return `/${locale}`;
  }

  if (locale === "") {
    if (lang === "es") {
      return location.pathname;
    }

    return location.pathname.replace(`/${lang}`, "");
  }

  if (lang === "es") {
    return `/${locale}${location.pathname}`;
  }

  return location.pathname.replace(`/${lang}`, `/${locale}`);
}

function isHomePage(pathname) {
  const lang = i18n.language;
  if (pathname.replace(lang, "") === "/") {
    return true;
  }
  return false;
}
