import React, { useState, useEffect } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import useDocumentTitle from "../../useDocumentTitle";

import { ThreeDots } from "react-loader-spinner";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const BlogCR = () => {
  useDocumentTitle("Invu POS | Blog Resources");

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedTypeContent, setSelectedTypeContent] = useState("All");
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [filteredData, setFilteredData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleFilterButtonClick = () => {
    setIsLoading(true);
    const resultsSection = document.getElementById("results-section");
    resultsSection.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simula una carga de 2 segundos
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const data = [
      {
        id: 1,
        name: "Software POS para restaurantes en Costa Rica",
        description:
          "En el dinámico mundo de la gastronomia en Costa Rica, la gestión eficiente es fundamental para el éxito continuo de cualquier establecimiento. Un sistema de punto de venta (POS) no es simplemente una herramienta para procesar transacciones; es la columna vertebral de la operación diaria, facilitando desde la toma de órdenes hasta la gestión del inventario y la generación de informes.",
        date: "March 5, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/software-pos-para-restaurantes-en-costarica",
        imageSrc:
          "https://img.invupos.com/invu%20costa%20rica/blog/software-pos-para-restaurantes-en-costarica.jpg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 2,
        name: "Estrategias para Aumentar las Ventas con un Sistema POS en tu Bar",
        description:
          "En la industria de la hospitalidad, especialmente en el sector de bares, aumentar las ventas es una prioridad constante. La implementación efectiva de un sistema POS no solo simplifica las operaciones, sino que también puede ser una herramienta poderosa para impulsar el crecimiento y la rentabilidad del negocio. En este artículo, exploraremos estrategias prácticas para aprovechar al máximo tu sistema POS y aumentar las ventas en tu bar.",
        date: "March 28, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/estrategias-para-aumentar-las-ventas-con-un-sistema-pos-en-tu-bar",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/estrategias-para-aumentar-las-ventas-con-un-sistema-pos-en-tu-bar.jpg",
        imageAlt: "",
        category: "Finances",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 3,
        name: "El Futuro del Café: Tendencias Tecnológicas que Debes Conocer en la Industria Cafetera",
        description:
          "El café, mucho más que una simple bebida, es una experiencia sensorial que ha evolucionado con el tiempo. En la era digital, la industria cafetera se encuentra en un punto de inflexión, donde la tecnología está transformando la forma en que se cultiva, se prepara y se disfruta esta popular bebida. En este artículo, exploraremos las tendencias tecnológicas que están dando forma al futuro del café y cómo la integración de un sistema POS puede potenciar estas innovaciones en las cafeterías de todo el mundo.",
        date: "April 3, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/el-futuro-del-cafe-tendencias-tecnologicas-que-debes-conocer-en-la-industria-cafetera",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/el-futuro-del-cafe-tendencias-tecnologicas-que-debes-conocer-en-la-industria-cafetera.jpg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 4,
        name: "Del Pedido a la Mesa: Cómo los Sistemas KDS Agilizan el Flujo de Trabajo en la Cocina",
        description:
          "En la dinámica y ajetreada atmósfera de un restaurante, la eficiencia en la cocina es fundamental para ofrecer una experiencia gastronómica excepcional a los comensales. En este sentido, los sistemas KDS (Kitchen Display Systems) han surgido como una herramienta indispensable, transformando por completo la forma en que los pedidos se procesan y se llevan a cabo en la cocina. Vamos a explorar cómo estos sistemas están optimizando el flujo de trabajo en la cocina y mejorando la experiencia general del restaurante.",
        date: "April 4, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/del-pedido-a-la-mesa-como-los-sistemas-kds-agilizan-el-flujo-de-trabajo-en-la-oficina",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/del-pedido-a-la-mesa-como-los-sistemas-kds-agilizan-el-flujo-de-trabajo-en-la-oficina.jpg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 5,
        name: "Optimizando tu Cocina con un KDS: Consejos y Trucos de un Chef",
        description:
          "En la agitada atmósfera de una cocina profesional, la coordinación y la eficiencia son fundamentales para mantener la calidad y la satisfacción del cliente. En este sentido, los sistemas de visualización de cocina (KDS, por sus siglas en inglés) han demostrado ser una herramienta invaluable para optimizar las operaciones y agilizar el servicio en restaurantes y establecimientos gastronómicos de todo tipo.",
        date: "April 8, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/optimizando-tu-cocina-con-un-kds-consejos-y-trucos-de-un-chef",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/optimizando-tu-cocina-con-un-kds-consejos-y-trucos-de-un-chef.jpg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 6,
        name: "5 Razones por las que tu Restaurante Necesita una Página Web Moderna",
        description:
          "En la era digital actual, tener una presencia en línea sólida es fundamental para el éxito de cualquier negocio, incluidos los restaurantes. Una página web moderna no solo es una herramienta de marketing poderosa, sino que también puede ser un factor determinante en la atracción y retención de clientes.",
        date: "April 11, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/cinco-razones-por-las-que-tu-restaurante-necesita-una-pagina-web-moderna",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/cinco-razones-por-las-que-tu-restaurante-necesita-una-pagina-web-moderna.jpg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 7,
        name: "Cómo Mejorar la Experiencia del Cliente con Tecnología en tu Bar",
        description:
          "En la industria de la hospitalidad, la experiencia del cliente es fundamental para el éxito de cualquier negocio, y los bares no son una excepción. Hoy en día, la tecnología desempeña un papel crucial en la mejora de esta experiencia, permitiendo a los bares ofrecer un servicio más eficiente, personalizado y atractivo para sus clientes.",
        date: "April 15, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/como-mejorar-la-experiencia-del-cliente-con-tecnologia-en-tu-bar",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/como-mejorar-la-experiencia-del-cliente-con-tecnologia-en-tu-bar.jpg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 8,
        name: "Cómo un sistema POS puede ayudar a impulsar las ventas en el restaurante del hotel",
        description:
          "Los eventos y banquetes son oportunidades únicas para los hoteles para aumentar sus ingresos y atraer a nuevos clientes. Sin embargo, gestionar eficazmente estos eventos puede ser un desafío logístico. Aquí es donde un sistema de punto de venta (POS) puede marcar la diferencia.",
        date: "April 23, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/como-un-sistema-pos-puede-ayudar-a-impulsar-las-ventas-en-el-restaurante-del-hotel",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/como-un-sistema-pos-puede-ayudar-a-impulsar-las-ventas-en-el-restaurante-del-hotel.webp",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 9,
        name: "Descubre donde comprar los Mejores Utensilios para un Chef",
        description:
          "Cuando se trata de equipar una cocina profesional o doméstica, la calidad de los utensilios es fundamental. Le Creuset, una marca reconocida por su excelencia en utensilios de cocina, ofrece una amplia gama de productos que son imprescindibles para cualquier chef. Desde sartenes y ollas hasta herramientas especializadas, los productos de Le Creuset combinan estilo, durabilidad y funcionalidad para satisfacer las necesidades de los chefs más exigentes.",
        date: "April 26, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/descubre-donde-comprar-los-mejores-utensilios-para-un-chef",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/descubre-donde-comprar-los-mejores-utensilios-para-un-chef.jpg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 10,
        name: "La Importancia de un Sistema POS en los Festivales: Optimizando la Experiencia del Cliente y la Gestión del Evento",
        description:
          "Los festivales son eventos vibrantes y dinámicos que atraen a multitudes ávidas de música, arte, comida y entretenimiento. Sin embargo, detrás de la diversión y el bullicio, existe una logística compleja que requiere una gestión eficiente para garantizar el éxito del festival. En este contexto, la implementación de un sistema de punto de venta (POS, por sus siglas en inglés) desempeña un papel crucial en la optimización de la experiencia del cliente y la gestión del evento. A continuación, exploramos la importancia de un sistema POS en los festivales:",
        date: "May 7, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/la-importancia-de-un-sistema-pos-en-los-festivales-optimizando-la-experiencia-del-cliente-y-la-gestion-del-evento",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/la-importancia-de-un-sistema-pos-en-los-festivales.jpeg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 11,
        name: "Los Beneficios del Delivery Online",
        description:
          "En el cambiante panorama de la industria alimentaria, adaptarse a las nuevas tendencias es fundamental para el éxito de un restaurante. En este artículo, exploraremos cómo el delivery online se ha convertido en una herramienta indispensable para los propietarios de restaurantes y cómo la integración con INVU POS puede potenciar aún más la eficiencia y el rendimiento de tu negocio.",
        date: "May 15, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/los-beneficios-del-delivery-online",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/los-beneficios-del-delivery-online.webp",
        imageAlt: "",
        category: "Delivery",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 12,
        name: "Cómo un sistema pos y control de inventario efectivos pueden hacer la diferencia",
        description:
          "Reducir las pérdidas y desperdicios es fundamental para la rentabilidad y sostenibilidad de cualquier negocio, especialmente en la industria de servicios alimentarios. En esta guía, exploraremos cómo un sistema POS y un control de inventario efectivos pueden ayudar a los propietarios de restaurantes a minimizar las pérdidas y maximizar las ganancias.",
        date: "May 29, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/como-un-sistema-pos-y-control-de-inventario-efectivos-pueden-hacer-la-diferencia",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/como-un-sistema-pos-y-control-de-inventario-efectivos-pueden-hacer-la-diferencia.webp",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 13,
        name: "Guía completa para elegir el mejor sistema POS para tu cafetería",
        description:
          "¿Estás listo para transformar tu cafetería con la tecnología adecuada? Elegir el sistema POS (Punto de Venta) perfecto puede ser el cambio que necesitas para llevar tu negocio al siguiente nivel. Con tantas opciones en el mercado, encontrar la mejor puede parecer un desafío. No te preocupes, estamos aquí para ayudarte. ",
        date: "June 7, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/guia-completa-por-elegir-el-mejor-sistema-pos-para-tu-cafeteria",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/guia-completa-por-elegir-el-mejor-sistema-pos-para-tu-cafeteria.webp",
        imageAlt: "",
        category: "Management",
        typecontent: "Guides",
        country: "Costa Rica", 
      },
      {
        id: 14,
        name: "Marketing digital para barberías: Estrategias para atraer y retener clientes",
        description:
          "En la era digital actual, el marketing para barberías ha evolucionado más allá de los métodos tradicionales. Para atraer y retener clientes, es crucial aprovechar las herramientas digitales y las estrategias de marketing modernas. En este artículo, exploraremos diversas estrategias de marketing digital para barberías y destacaremos cómo INVU POS, con su funcionalidad de comercio electrónico y su POS especializado, puede ayudarte a alcanzar tus objetivos de negocio.",
        date: "June 13, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/marketing-digital-para-barberias-estrategias-para-atraer-y-retener-clientes",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/marketing-digital-para-barberias-estrategias-para-atraer-y-retener-clientes.jpeg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 15,
        name: "Cómo elegir el mejor sistema POS para tu estudio de tatuajes: Una guía completa",
        description:
          "Dirigir un estudio de tatuajes es una combinación única de arte, creatividad y gestión empresarial. En el corazón de esta operación se encuentra un sistema POS (Punto de Venta) eficiente y especializado. ¿Pero cómo elegir el mejor sistema POS para tu estudio de tatuajes? En esta guía exhaustiva, exploraremos los aspectos clave que debes considerar, junto con la funcionalidad y los beneficios que ofrece INVU POS para estudios de tatuajes.",
        date: "June 17, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/como-elegir-el-mejor-sistema-pos-para-tu-estudio-de-tatuajes-una-guia-completa",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/como-elegir-el-mejor-sistema-pos-para-tu-estudio-de-tatuajes-una-guia-completa.webp",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 16,
        name: "INVU POS anuncia nueva integración con HERO para mejorar la contabilidad y gestión financiera de sus clientes.",
        description:
          "Ciudad de Panamá,– Invu POS, líder en soluciones de punto de venta, se complace en anunciar su reciente integración con Hero, una innovadora plataforma de contabilidad y gestión financiera. Esta colaboración tiene como objetivo simplificar y automatizar los procesos contables para los usuarios de Invu POS, proporcionando una solución integral que conecta directamente las ventas con la contabilidad.",
        date: "June 27, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/invupos-anuncia-nueva-integracion-con-hero-para-mejorar-la-contabilidad-y-gestion-financiera-de-sus-clientes",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/hero-integrations.webp",
        imageAlt: "",
        category: "Press release",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 17,
        name: "El mejor sistema POS para Spas y salones de belleza",
        description:
          "La gestión de inventarios es un aspecto crítico para el éxito de cualquier spa. Mantener un equilibrio adecuado entre la oferta y la demanda de productos de belleza puede ser un desafío constante. Un sistema POS (Point of Sale) especializado puede ser la solución ideal para optimizar este proceso, asegurando que los productos necesarios estén siempre disponibles sin exceder en inventario. ",
        date: "July 5, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/el-mejor-sistema-pos-para-spas-y-salones-de-belleza",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/el-mejor-sistema-pos-para-spas-y-salones-de-belleza.jpeg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 18,
        name: "Cómo Elegir el Mejor Sistema POS para tu Cervecería Artesanal",
        description:
          "Elegir el sistema de punto de venta (POS) adecuado es fundamental para cualquier cervecería artesanal que busque optimizar sus operaciones, mejorar la experiencia del cliente y maximizar sus ingresos. Un buen sistema POS no solo gestiona las ventas, sino que también integra múltiples aspectos del negocio, desde la gestión de inventarios hasta la fidelización del cliente. ",
        date: "July 24, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/como-elegir-el-mejor-sistema-pos-para-tu-cerveceria-artesanal",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/como-elegir-el-mejor-sistema-pos-para-tu-cerveceria-artesanal.webp",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
      {
        id: 19,
        name: "Cómo los Gift Cards Pueden Impulsar las Ventas en tu Salón de Belleza",
        description:
          "Los gift cards son una herramienta poderosa que puede transformar la manera en que operas tu salón de belleza. No solo son una excelente manera de aumentar las ventas, sino que también pueden atraer nuevos clientes y fidelizar a los existentes. En este artículo, exploraremos cómo implementar un sistema de gift card puede beneficiar a tu salón y por qué INVU POS es la solución perfecta para gestionar este proceso de manera eficiente y efectiva.",
        date: "August 2, 2024",
        views: "2.3k",
        comments: "27",
        href: "/blog/como-los-gift-cards-pueden-impulsar-las-ventas-en-tu-salon-de-belleza",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/como-los-gift-cards-pueden-impulsar-las-ventas-en-tu-salon-de-belleza.webp",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Costa Rica", 
      },
  ];

  const sortedData = data.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  const sortedFilteredData = filteredData
  ? filteredData.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
  : null;

  const filterItems = () => {
    let filteredData;
  
    if (
      selectedCategory === "All" &&
      selectedTypeContent === "All" &&
      selectedCountry === "All"
    ) {
      // If all filters are "All", show all blogs
      filteredData = data;
    } else {
      // Apply filtering logic based on selected values
      filteredData = data.filter((item) => {
        const categoryCondition = selectedCategory === "All" || item.category === selectedCategory;
        const typeContentCondition = selectedTypeContent === "All" || item.typecontent === selectedTypeContent;
        const countryCondition =
          selectedCountry === "All" || (Array.isArray(item.countries) ? item.countries.includes(selectedCountry) : item.country === selectedCountry);
  
        return categoryCondition && typeContentCondition && countryCondition;
      });
    }
  
    setFilteredData(filteredData);
  
    if (filteredData.length === 0) {
      setErrorMessage(
        `No items found for ${selectedTypeContent} ${selectedCategory} ${selectedCountry}`
      );
    } else {
      setErrorMessage("");
    }
  };

  const resetFilter = () => {
    setSelectedCategory("All");
    setSelectedTypeContent("All");
    setSelectedCountry("All");
    setFilteredData(data);
    setErrorMessage("");
  };

  const uniqueCategories = [
    "All",
    ...new Set(data.map((item) => item.category)),
  ];
  const uniqueTypeContent = [
    "All",
    ...new Set(data.map((item) => item.typecontent)),
  ];

  const location = useLocation();
  const prefix = useLocalePrefix();
  const { t } = useTranslation();
  return (
    <>
      {isLoading && (
        <div class="fixed inset-0 flex items-center justify-center"> 
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      <section class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <div class="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
          <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
            {t("ourbloguppercase")}
          </h2>
          <h1 class="mb-6 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-5xl md:tracking-tight">
            {t("ourblogtitle1")}{" "}
            <span class="block w-full text-transparent bg-clip-text bg-gradient-to-r from-[#000C8A] to-[#DB2415] lg:inline">
              {t("ourblogtitlespan")}
            </span>{" "}
            {t("ourblogtitle2")}
          </h1>
        </div>

        <div
          class="
                  p-2
                  mt-16
                  transition
                  duration-500
                  ease-in-out
                  transform
                  border2
                  bg-gray-50
                  md:mx-auto
                  rounded-xl
                  sm:max-w-5xl
                  lg:flex
                  mb-8
                "
        >
          <div class="lg:flex space-x-12">
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                class="
                block
                w-full
                px-5
                py-3
                text-base text-neutral-600
                placeholder-gray-300
                transition
                duration-500
                ease-in-out
                transform
                bg-transparent
                border border-transparent
                rounded-md
                focus:outline-none
                focus:border-transparent
                focus:ring-2
                focus:ring-white
                focus:ring-offset-2
                focus:ring-offset-gray-300
              "
              >
                {/* {uniqueCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))} */}
                <option value="All">{t("categoryblog1")}</option>
                <option value="Finances">{t("categoryblog2")}</option>
                {/* <option value="Payments">{t("categoryblog3")}</option> */}
                {/* <option value="Customers">{t("categoryblog4")}</option> */}
                <option value="Management">{t("categoryblog5")}</option>
                {/* <option value="Marketing">{t("categoryblog6")}</option> */}
                {/* <option value="Press release">{t("categoryblog7")}</option>
                <option value="Informative">{t("categoryblog8")}</option> */}
                {/* <option value="System POS">Sistema POS</option> */}
                <option value="Delivery">Delivery</option>
                
                {/* <option value="Educational">{t("categoryblog9")}</option> */}
              </select>
            </div>
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                value={selectedTypeContent}
                onChange={(e) => setSelectedTypeContent(e.target.value)}
                class="
                block
                w-full
                px-5
                py-3
                text-base text-neutral-600
                placeholder-gray-300
                transition
                duration-500
                ease-in-out
                transform
                bg-transparent
                border border-transparent
                rounded-md
                focus:outline-none
                focus:border-transparent
                focus:ring-2
                focus:ring-white
                focus:ring-offset-2
                focus:ring-offset-gray-300
              "
              >
                {/* {uniqueTypeContent.map((typecontent) => (
                  <option key={typecontent} value={typecontent}>
                    {typecontent}
                  </option>
                ))} */}
                <option value="All">{t("typecontentblog1")}</option>
                <option value="Articles">{t("typecontentblog2")}</option>
                <option value="Guides">{t("typecontentblog3")}</option>
                {/* <option value="Tools">{t("typecontentblog4")}</option> */}
              </select>
            </div>
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
                class="
                block
                w-full
                px-5
                py-3
                text-base text-neutral-600
                placeholder-gray-300
                transition
                duration-500
                ease-in-out
                transform
                bg-transparent
                border border-transparent
                rounded-md
                focus:outline-none
                focus:border-transparent
                focus:ring-2
                focus:ring-white
                focus:ring-offset-2
                focus:ring-offset-gray-300"
              >
                <option value="All">Todos los países</option>
                {/* <option value="Panama">Panamá</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Mexico">México</option> */}
                <option value="Costa Rica">Costa Rica</option>
              </select>
            </div>

          </div>
          <div class="mt-4 sm:mt-0 lg:ml-3 revue-form-actions">
            <button
              onClick={() => {
                filterItems();
                handleFilterButtonClick();
              }}
              class="
               block
               w-full
               px-5
               py-3
               text-base
               text-white
               bg-indigo-600
               border border-transparent
               rounded-lg
               shadow
               font-semibold
               hover:bg-indigo-700
               focus:outline-none
               focus:ring-2
               focus:ring-white
               focus:ring-offset-2
               focus:ring-offset-gray-300
               sm:px-10
             "
            >
              {t("filtersearchblogbutton")}
            </button>
          </div>
        </div>

        {/* <div class="grid grid-cols-1 mt-24 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
          <div>
            <Link
              onClick={() => window.scrollTo(0, 0)}
              class="block"
              to={`${prefix}` + "/blog/programas-de-contabilidad-en-la-nube"}
            >
              <img
                class="object-cover w-full h-82 shadow-xl rounded-xl"
                src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2021/Cover%20blog%2021%20.jpeg"
                alt=""
              />

              <div class="mt-10">
                <div class="h-full flex items-start">
                  <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                    <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">
                      Jul
                    </span>
                    <span class="font-medium text-lg text-gray-800 title-font leading-none">
                      07
                    </span>
                  </div>
                  <div class="flex-grow pl-6">
                    <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1 uppercase">
                      Destacado
                    </h2>
                    <h1 class="title-font max-w-md text-xl font-semibold text-gray-900 mb-3">
                      Software de contabilidad y facturación en la nube
                    </h1>
                    <p class="mt-2 max-w-lg text-gray-600 ">
                      A día de hoy, y gracias al avance de la tecnología, los
                      softwares de contabilidad en la nube se han convertido en
                      muy valiosas herramientas de trabajo para los
                      departamentos de contabilidad de las empresas.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link
              class="block"
              onClick={() => window.scrollTo(0, 0)}
              to={`${prefix}` + "/blog/facturacion-electronica-dgi-2022"}
            >
              <img
                class="object-cover w-full h-82 shadow-xl rounded-xl"
                src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2022/Cover%20blog%2022%20.jpeg"
                alt=""
              />

              <div class="mt-10">
                <div class="h-full flex items-start">
                  <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                    <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">
                      Jul
                    </span>
                    <span class="font-medium text-lg text-gray-800 title-font leading-none">
                      08
                    </span>
                  </div>
                  <div class="flex-grow pl-6">
                    <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1">
                      DESTACADO
                    </h2>
                    <h1 class="title-font max-w-md text-xl font-semibold text-gray-900 mb-3">
                      Facturación electrónica en Panamá: requisitos y novedades
                    </h1>
                    <p class="mt-2 max-w-lg text-gray-600 ">
                      En Panamá, la factura electrónica es un modelo de
                      facturación que, además de reemplazar el uso de impresoras
                      fiscales, acelera la transformación digital de las
                      empresas e impulsa el ingreso de los emprendedores en la
                      economía formal.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div> */}
      </section>
      {showButton && (
        <>
          <button
            onClick={handleScrollToTop}
            class="fixed bottom-0 right-0 m-4 p-2 group flex items-center justify-between gap-4 rounded-lg border border-indigo-600 bg-indigo-600 px-5 py-3 transition-colors hover:bg-indigo-700 focus:outline-none focus:ring"
          >
            <span class="font-medium text-white transition-colors group-hover:text-white group-active:text-indigo-500">
              {t("fixedbuttonscroll")}
            </span>

            <span class="flex-shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>
        </>
      )}
      <section id="results-section" className="bg-gray-100">
        <div className="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <h1 className="-mt-8 text-4xl font-bold xl:block hidden leading-tight text-gray-800">
            {t("titlelistblog")}
          </h1>
          <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>

          <h1 className="-mt-8 text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-gray-800">
            {t("titlelistblog")}
          </h1>
          <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>
          <div>
            {" "}
            {errorMessage && (
              <>
                <div class="rounded-3xl mt-8 shadow-2xl">
                  <div class="p-8 text-center sm:p-12">
                    <p class="text-sm font-semibold uppercase tracking-widest text-indigo-500">
                      {t("errormessageblogs")}
                    </p>

                    <h2 class="mt-6 text-3xl font-bold">{errorMessage}</h2>

                    <button
                      class="mt-8 inline-block lg:w-96 md:w-96 w-52 rounded-full bg-indigo-600 py-4 text-lg font-bold text-white transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring active:bg-indigo-500"
                      onClick={resetFilter}
                    >
                      {t("buttonrefreshblogs")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="grid gap-6  grid-cols-1 mt-8 grid grid-cols-1 gap-12 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3">
            {!filteredData
              ? sortedData.map((item, index) => (
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    to={`${prefix}` + item.href}
                    key={index}
                  >
                    <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                      <a aria-label="Article">
                        <img
                          src={item.imageSrc}
                          className="object-cover w-full h-64 rounded-t-lg"
                          alt=""
                        />
                      </a>
                      <div className="py-5 p-6">
                        <span class="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700">
                          {item.typecontent}
                        </span>
                        <div className="flex justify-between items-end mt-3">
                          {item.countries && Array.isArray(item.countries) ? (
                            <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                              {item.date} | {item.countries.join(', ')}
                            </p>
                          ) : (
                            <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                              {item.date} | {item.country}
                            </p>
                          )}
                        </div>
                        <a
                          aria-label="Article"
                          className="inline-block mt-2.5 mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                        >
                          <p className="text-2xl font-bold leading-7">
                            {item.name.length > 72
                              ? `${item.name.substring(0, 72)}...`
                              : item.name}
                          </p>
                        </a>
                        <p className="mb-4 text-gray-700">
                          {item.description.length > 240
                            ? `${item.description.substring(0, 238)}...`
                            : item.description}
                        </p>
                        <div class="mt-1.5 sm:mt-0">
                          <p class="text-gray-500 italic text-sm">{item.category}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
                : sortedFilteredData.map((item, index) => (
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    to={`${prefix}` + item.href}
                    key={index}
                  >
                    <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                      <a aria-label="Article">
                        <img
                          src={item.imageSrc}
                          className="object-cover w-full h-64 rounded-t-lg"
                          alt=""
                        />
                      </a>
                      <div className="py-5 p-6">
                      <span class="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700">
                          {item.typecontent}
                        </span>
                        <div className="flex justify-between items-end mt-3">
                          {item.countries && Array.isArray(item.countries) ? (
                            <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                              {item.date} | {item.countries.join(', ')}
                            </p>
                          ) : (
                            <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                              {item.date} | {item.country}
                            </p>
                          )}
                        </div>
                        <a
                          aria-label="Article"
                          className="inline-block mt-2.5 mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                        >
                          <p className="text-2xl font-bold leading-7">
                            {item.name.length > 72
                              ? `${item.name.substring(0, 72)}...`
                              : item.name}
                          </p>
                        </a>
                        <p className="mb-4 text-gray-700">
                          {item.description.length > 240
                            ? `${item.description.substring(0, 238)}...`
                            : item.description}
                        </p>
                        <div class="mt-1.5 sm:mt-0">
                          <p class="text-gray-500 italic text-sm">{item.category}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogCR;
